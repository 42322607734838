.Advantage-content {
  color: white !important;
  width: 13rem;
  height: 13rem;
  max-width: 13rem;
  max-height: 13rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 1rem;
  .Advantage-tittle {
    margin: 1rem 0;
  }
  .Advantage-text {
    display: none;
  }
  .Advantage-icon {
    display: inline;
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 0.5s;
  }
  .yellow-color {
    color: rgb(255, 200, 0);
  }
}

.Advantage-content:hover {
  .Advantage-tittle {
    animation: text-up;
    animation-fill-mode: both;
    animation-duration: 0.5s;
  }
  .Advantage-text {
    display: block;
    animation-name: fade-in;
    animation-fill-mode: both;
    animation-duration: 0.5s;
  }
  .Advantage-icon {
    display: none;
  }
}
.Advantage-content:not(:hover) {
  .Advantage-tittle {
    animation: text-down;
    animation-duration: 0.5s;
  }
  .Advantage-text {
    animation-name: fade-out;
    animation-duration: 0.5s;
  }
  .Advantage-icon {
    animation: text-down;
    animation-duration: 0.5s;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes text-up {
  0% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(-60%);
    opacity: 1;
  }
}

@keyframes text-down {
  0% {
    transform: translateY(-60%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
