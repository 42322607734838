@import '../../../../assets/styles/variables.scss';
.summary-class {
  width: 20rem;
  max-height: 19rem;
  border-image: linear-gradient($cyan-cyan-light, $dark-blue) 30;
  border-width: 4px;
  border-style: solid;
  .summary-class-container {
    height: 100%;
    align-content: end;
    .title {
      padding: 0.5em;
      font-weight: 600;
    }
    .summary-class-button {
      background-color: $cyan-cyan-dark;
      color: aliceblue;
      text-align: center;
    }
    .summary-class-button:hover {
      background-color: $pale-cyan;
    }
    & ul {
      padding: 0 !important;
    }
  }

  .subscribe {
    height: 100%;
    align-content: end;
    .subscribe-media {
      height: 10rem;
    }
    .subscribe-content {
      height: 5rem;
      padding: 0.5rem 1rem;
    }
    .actions-subscribe {
      background-color: $cyan-cyan-dark;
      .button-subscribe {
        width: 100%;
      }
    }
  }
}
.summary-class-list {
  min-height: 10rem;
  color: $light-gray;

  .padding-list {
    padding: 0 1rem;
  }
}
