@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

footer {
  background-color: $dark-blue;
  color: $pale-blue;
  width: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    color: whitesmoke;
  }

  & .contact-info-list {
    display: inline-flex;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0;

    > * {
      list-style-type: none;
      margin: 0 0.5rem;
      display: flex;
      text-align: center;
      align-items: flex-start;
      height: 5rem;
      width: 14rem;
      > b {
        text-align: center;
        vertical-align: middle;

        .whatsapp-icon {
          font-size: larger;
        }
      }
      .list-container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    .link-color {
      color: $light-pale-blue;
    }

    @include responsive('small') {
      flex-direction: column;
      .email {
        height: 3rem;
      }

      > * {
        padding-bottom: 1em;
      }
    }

    @include responsive('medium') {
      flex-direction: row;
    }

    @include responsive('large') {
      flex-direction: row;
    }
  }
}
