.whatsapp-fab {
    position: fixed !important;
    right: 0;
    bottom: 0;
    z-index: 999;
    margin: 1em !important;

    .icon {
        width: 4em;
        height: auto;
    }
}