@import '../../../assets/styles/variables.scss';
.payment-container {
  height: 100%;
  color: $darker-gray !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  .payment-table-container {
    background-color: $light-pale-cyan;
    margin: 2rem 0;
    padding: 0;
    .accordion-title {
      background-color: $light-pale-cyan;
      padding: 1.5rem;
      color: $darker-gray !important;
    }
    & svg {
      color: $cyan-cyan-dark;
    }
    .grid-container {
      color: $darker-gray !important;
      .status {
        display: inline-block;
        .space {
          padding-right: 0.5rem;
        }
        .dot {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
        .yellow {
          color: rgb(255, 225, 0);
        }
        .green {
          color: rgb(42, 159, 42);
        }
        .red {
          color: rgb(163, 33, 33);
        }
        .gray {
          color: $light-gray;
        }
      }
      .grid-item {
        border-bottom: 2px solid $light-cyan;
        padding: 0.5rem;
        min-height: 4rem;
        align-content: center;
        justify-content: space-around;
        word-break: break-word;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;

        & svg {
          font-size: 1.2em;
        }
      }
    }
  }
  .info-container {
    padding: 2rem 0;
    width: 100%;
  }
}
