@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/variables.scss';

@include titles(2em);

.methodology-section {
  background-color: white;
  padding: 3em 0 !important;
  & .methodology-content {
    padding: 2em 0;
  }
}

h2 {
  margin: 0;
  font-size: 1.7em;
}
.icon {
  color: $cyan-cyan-dark;
}

.methodology-text {
  text-align: left !important;
  color: $light-gray;
  padding-bottom: 1rem;
  padding-right: 1rem;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  font-size: 1.1em;
  .text-title {
    padding-bottom: 0.25em;
  }
}
