@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.login-image-logo {
  background-repeat: no-repeat;
  background-color: $light-pale-cyan;
  background-size: cover;
  background-position: center;
}
.login {
  background-image: linear-gradient(to right, $light-cyan, $dark-blue);
  background-size: 200% 200%;
  animation: gradientAnimation 5s infinite alternate;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .login-container {
    width: 30vw;
    padding: 3em;
    .login-avatar {
      border: medium solid $dark-blue !important;
    }
    @include responsive('small') {
      width: 90vw;
    }
    @include responsive('medium') {
      width: 70vw;
    }
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
