@import '../../../assets/styles/variables.scss';
@import '/src/assets/styles/mixins.scss';
.advantages-section {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @include titles(2em);

  .advantages-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    > * {
      margin: 0.5rem 0.5rem;
    }
  }

  @media screen and (min-width: $big-screen-min-width) {
    height: 35em;
    & h1 {
      padding: 3rem;
    }
    .advantages-body {
      flex-direction: row;
    }
  }

  @media screen and (min-width: $medium-screen-min-width) and (max-width: $medium-screen-max-width) {
    height: 60em;
    & h1 {
      padding: 2rem;
    }

    .advantages-body {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  @media screen and (min-width: $small-screen-min-width) and (max-width: $small-screen-max-width) {
    height: 90em;
    & h1 {
      padding: 1rem;
    }
    .advantages-body {
      flex-direction: column;
    }
  }
}
