$light-pale-blue: #95ace9;
$pale-blue: #6a84bc;
$dark-blue: #103c93;
$light-pale-cyan: #dbf3f7;
$flourescent-cyan: #04dbbb;
$pale-cyan: #9ae6e1;
$light-cyan: #63d3c9;
$greeanest-cyan: #34c3ac;
$cyan-cyan-dark: #30c4b4;
$cyan-cyan-light: #29ccb4;
$light-gray: #7d8080;
$darker-gray: #4b4b4b;
$transparent-cyan: #04dbbb7b;

$big-screen-min-width: 980px;
$medium-screen-min-width: 760px;
$medium-screen-max-width: 980px;
$small-screen-min-width: 280px;
$small-screen-max-width: 760px;

.button-text {
  color: white !important;
  text-decoration: none !important;
}

.gray-bg {
  background-color: #e3e8f2;
}
