@import '../../../assets/styles/variables.scss';

.scroll-button-container {
  position: absolute;
  right: 150px;
  bottom: -490px;
}
.scroll-button {
  background-color: $flourescent-cyan !important;
  color: white !important;
}

.scroll-button:hover {
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 6px 9px -3px rgba(0, 0, 0, 0.4);
}
