@import '../../../assets/styles/variables.scss';
.marketing-campaigns {
  max-width: 100% !important;
  .promos-section {
    border-style: dotted;
    width: 100% !important;
  }
}

.preview {
  color: $light-gray;
  & img {
    opacity: 0.8;
  }
  .preview-title {
    padding: 2rem;
  }
}
.edit {
  padding: 1rem 0;
  .space {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .edit-drop {
    height: 30rem;
    .drop-area {
      height: 23rem;
    }
  }

  .save,
  .delete {
    & button {
      width: 20rem;
    }
  }
}
