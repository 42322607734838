@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/fonts.scss';
@import '../../../assets/styles/mixins.scss';

.textblock-container {
  width: 100vw;
  height: 20rem;
  background-color: $dark-blue;
  color: white;
  & div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & span {
    font-style: italic;
    font-size: 2rem;
    font-weight: 300;
    @include responsive('small') {
      font-size: 1.5rem;
    }
  }
}
