@import '../../../../assets/styles/variables.scss';
.is-points-summary {
  width: 10em;
  height: 10em;
  border-image: linear-gradient($cyan-cyan-light, $pale-blue) 30;
  border-width: 4px;
  border-style: solid;
  color: $light-gray;
  padding: 1em;
}
