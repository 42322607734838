@import '../../../assets/styles/variables.scss';
@import '/src/assets/styles/mixins.scss';

.promos-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  overflow-y: hidden;

  .promos-container {
    text-align: left;
    align-content: center;

    @include titles(2em);

    .promos-text {
      color: $light-gray;
    }

    .promos-button {
      background-color: $dark-blue;
    }
  }

  .promos-img-container {
    justify-content: flex-end;
    .img-container {
      display: flex;
      flex-basis: fit-content;
      justify-content: flex-end;
      width: -webkit-fill-available;
    }

    .promos-img {
      width: 90%;
      height: auto;
    }
  }

  @media screen and (min-width: $big-screen-min-width) {
    height: 35rem;
    .space-between {
      padding: 1.5rem 0;
    }

    .promos-text {
      line-height: 2rem;
    }
  }

  @media screen and (min-width: $medium-screen-min-width) and (max-width: $medium-screen-max-width) {
    height: 50vh;

    .space-between {
      padding: 2rem 0;
    }

    .promos-text {
      line-height: 2rem;
    }
  }

  @media screen and (min-width: $small-screen-min-width) and (max-width: $small-screen-max-width) {
    height: 30rem;

    .space-between {
      padding: 1rem 0;
    }

    .promos-text {
      line-height: 1.5rem;
    }
  }
}
