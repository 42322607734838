@import '../../../assets/styles/variables.scss';

.MuiMenu-list {
  background-color: $dark-blue !important;
  color: whitesmoke !important;
}
.sign-menu {
  text-align: end;
  align-content: center;
  svg {
    color: whitesmoke !important;
  }
}
