.side-menu {
  & svg {
    color: white !important;
    width: 100%;
  }

  .side-menu-icon {
    font-size: 1.5em;
  }

  .logo-img-container {
    width: 100%;
    padding: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    img {
      width: 100%;
      height: auto;
    }
  }
}
